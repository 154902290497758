import {createBrowserHistory} from 'history';
import {generatePath, RouteProps as ReactRouteProps} from 'react-router-dom';

import {PaymentRoutes} from 'features/payment/routes';
import {SetupRoutes} from 'features/setup/routes';
import {InstalmentPlanRoutes} from 'features/instalment-plan/routes';
import {ProfileRoutes} from 'features/profile/routes';
import {URLSearchParams} from 'url';
import {InfringementRoutes} from 'features/infringement/routes';
import {NotificationRoutes} from 'features/notification/routes';

export const history = createBrowserHistory();

type BaseRoutes = {
  '/biller/:slug/direct-debit': {
    slug: string;
  };
  '/biller/:slug/infringements/*': {
    slug: string;
  };
  '/biller/:slug/setup/*': {
    slug: string;
  };
  '/biller/:slug/support': {
    slug: string;
  };
  '/biller/:slug/profile/*': {
    slug: string;
  };
  '/biller/:slug/instalment-plan/:instalmentPlanId/*': {
    slug: string;
    instalmentPlanId: string;
  };
  '/biller/:slug/confirmation': {
    slug: string;
  };
  '/biller/:slug': {
    slug: string;
  };
  '/not-found': {};
};

export type Routes = BaseRoutes &
  PaymentRoutes &
  SetupRoutes &
  InstalmentPlanRoutes &
  ProfileRoutes &
  InfringementRoutes &
  NotificationRoutes;

export type RouteName = keyof Routes;

export type Params<Name extends RouteName> = Routes[Name];

// Extend a type safe version of Route Props
export type RouteProps = ReactRouteProps & {
  path: RouteName;
};

export function navigate<Name extends RouteName>(
  name: Name,
  params?: Params<Name>,
  replace = false,
  searchParams?: URLSearchParams
): void {
  const path =
    searchParams && searchParams.toString() !== ''
      ? `${generatePath(name, params)}?${searchParams.toString()}`
      : generatePath(name, params);
  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }
}

export function goBack() {
  history.back();
}

export function goTo(count: number) {
  history.go(count);
}

export function replace(name: string) {
  history.replace(name);
}

export function getSearchParamValue(key: string) {
  return new URL(window.location.href).searchParams.get(key);
}
