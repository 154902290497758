import {Debbie} from 'components/organisms/Debbie';
import {CheckIcon} from '@heroicons/react/20/solid';
import {goBack, navigate} from 'lib/navigation/routes';
import {useInfringementParams} from '../hooks/useInfringementParams';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {markDownToHtml} from 'lib/markDownToHtml';

export const FlexibleFeatures = () => {
  const {infringementType} = useInfringementParams();
  const billerConfig = useBillerConfig();
  const {
    loading,
    data: account,
    error,
  } = useGetAccountFromSearchParams({anonymous: true});

  if ((loading || !account) && !error) return <Loading />;

  return (
    <>
      <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title="You have selected to create a flexible payment plan" />
          <div className="mt-4 overflow-hidden rounded-md shadow">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="flex flex-col justify-center flex-1 h-full">
                {billerConfig.infringements?.enabled &&
                billerConfig.infringements.terms ? (
                  billerConfig.infringements.terms.map(p => (
                    <p
                      className="text-gray-600 mb-4 [&>ul]:list-disc [&>ul]:list-inside"
                      dangerouslySetInnerHTML={{
                        __html: markDownToHtml(p, ['ul', 'li']),
                      }}
                    />
                  ))
                ) : (
                  <>
                    <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
                      This means that you
                    </h3>
                    <dl className="text-base leading-7 text-gray-600">
                      {[
                        'Choose your payment frequency',
                        'Will stay up to date with your plan via SMS notifications',
                        'Can update your payment method anytime',
                      ].map((feature, key) => (
                        <div key={key} className="relative mt-3 pl-9">
                          <dt className="text-gray-900 font-regular">
                            <CheckIcon
                              className="absolute left-0 w-5 h-5 text-blue-900 top-1"
                              aria-hidden="true"
                            />
                            {feature}
                          </dt>
                        </div>
                      ))}
                    </dl>
                  </>
                )}
              </div>
            </div>
          </div>

          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => {
              navigate(
                '/biller/:slug',
                {
                  slug: billerConfig.billerSlug,
                },
                false,
                new URLSearchParams({
                  goTo: `/biller/${billerConfig.billerSlug}/setup/link-contact/infringement?${new URLSearchParams(
                    {
                      infringementType,
                      accountType: account.type,
                      accountExternalId: account.externalId,
                    }
                  )}`,
                })
              );
            }}
          >
            Next
          </button>
          <button
            className="mt-6 text-blue-600 transition hover:text-blue-700"
            onClick={goBack}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
