import React, {useContext, useEffect} from 'react';
import {useActor} from '@xstate/react';
import NumberFormat from 'react-number-format';

import {Debbie} from 'components/organisms/Debbie';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';

import * as Auth from 'lib/auth/Auth';
import {Contact, useGetInstalmentPlanQuery} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';

import _refiner from 'refiner-js';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {capitalize, formatToDollars} from 'payble-shared';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';

type ConfirmationInstalmentPlanProps = {
  billerSlug: string;
  instalmentPlanId: string;
  contact: Contact;
};

export const ConfirmationInstalmentPlan: React.FC<
  ConfirmationInstalmentPlanProps
> = ({billerSlug, contact, instalmentPlanId}) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(billerSlug);
  const billerConfig = useBillerConfig();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    if (billerSlug === 'city-of-kingston') {
      _refiner('showForm', '6e8d8fe0-fb61-11ec-9d73-493856f3d7b9');
    } else if (billerSlug === 'city-of-exampleton') {
      _refiner('showForm', '2ae93970-0cad-11ed-8aee-798d80f31984');
    } else if (billerSlug === 'moreton-bay') {
      _refiner('showForm', '6a1d1230-8d75-11ed-9577-1f6ec04f1f1d');
    }
  }, []);

  const {data, loading, error} = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data?.instalmentPlan) return <ErrorMessage message="No payment plan" />;

  const biller = authState.context.biller;

  if (!biller) return <div>No contact or biller</div>;

  const paymentMethod = contact.paymentMethods?.find(
    x => x.id === data.instalmentPlan?.paymentMethodId
  );
  if (!paymentMethod) {
    console.log(authState.context);
    return <div>No payment method</div>;
  }

  const nextInstalmentDueAt = data.instalmentPlan.nextInstalmentDueAt;

  const debbieTitle = nextInstalmentDueAt
    ? `Your payments have been scheduled to start on ${nextInstalmentDueAt.toFormat(
        'dd LLL yyyy'
      )}`
    : '';

  let debbieMessage = `All done ${contact.givenName}`;
  if (nextInstalmentDueAt) {
    debbieMessage += `, your first payment to ${
      biller.name
    } will be made ${nextInstalmentDueAt.toFormat(
      'dd LLL yyyy'
    )}. We'll send a receipt via SMS - just click the link if you ever need to view or modify your schedule.`;
  }

  return (
    <div
      className="flex flex-1 flex-col h-full w-full overflow-hidden p-4 max-w-xl m-auto"
      data-testid="payInFullConfirmation"
    >
      <Debbie title={debbieTitle} message={debbieMessage} />
      <div className="shadow overflow-hidden rounded-md mt-5">
        <div className="px-4 py-4 bg-white sm:p-6">
          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm text-gray-600">
            <div className="border-b pb-4 col-span-2">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                data-testid="header"
              >
                Payment plan {data?.instalmentPlan.account.externalId}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {capitalize(
                  billerConfig.getAccountTypeTitle(
                    data?.instalmentPlan.account.type
                  )
                )}{' '}
                for <br />
                {data?.instalmentPlan.account.description}
              </p>
            </div>
            <div>
              <dt className="font-medium text-gray-900 mb-2">
                Contact Information
              </dt>
              <dd>
                <address className="not-italic">
                  <span className="block break-words">
                    {contact.givenName} {contact.familyName}
                  </span>
                  <span className="block">
                    <PhoneNumberFormat
                      value={contact.mobile}
                      region={billerConfig.region}
                    />
                  </span>
                  <span className="block break-words">{contact.email}</span>
                </address>
              </dd>
            </div>
            <div>
              <dt className="font-medium text-gray-900 mb-2">
                Payment Information
              </dt>
              <PaymentMethodRow paymentMethod={paymentMethod} />
            </div>
          </dl>

          <dl className="text-sm font-medium text-gray-500 space-y-3 mt-6">
            <div className="flex items-center justify-between border-t text-gray-900 pt-3">
              <dt className="text-sm">Total</dt>
              <dd className="text-sm">
                <NumberFormat
                  value={formatToDollars(data.instalmentPlan.amount)}
                  displayType={'text'}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
            </div>
          </dl>
          <dl className="text-sm font-medium text-gray-500 space-y-3 mt-3">
            <div className="flex items-center justify-between border-t text-gray-900 pt-3 relative">
              <dt className="text-sm">
                {data.instalmentPlan.instalments.length} Payments
              </dt>
              <dd className="text-sm">
                <NumberFormat
                  value={(
                    (data.instalmentPlan.amount as number) /
                    data.instalmentPlan.instalments.length /
                    100
                  ).toFixed(2)}
                  displayType={'text'}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
              <button
                onClick={() =>
                  navigate(
                    '/biller/:slug/instalment-plan/:instalmentPlanId/schedule',
                    {slug: billerSlug, instalmentPlanId}
                  )
                }
                className="absolute right-20 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-lg text-xs top-2 focus:outline-none focus:ring-offset-2 border-transparent focus:ring-2 focus:ring-gray-200"
              >
                See more
              </button>
            </div>
          </dl>
          <RolloverNotice showNotice={hasYearlyRollOver} />
        </div>
      </div>
      <div className="flex-1 flex items-center">
        <button
          onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
          className="disabled:opacity-50 disabled:cursor-not-allowed mt-4 w-full text-center justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to home
        </button>
      </div>
    </div>
  );
};
