import React, {useContext, useEffect} from 'react';
import NumberFormat from 'react-number-format';
import {useParams} from 'react-router';

import {HomeIcon} from '@heroicons/react/20/solid';
import {Loading} from 'components/atoms/Loading';
import {Debbie} from 'components/organisms/Debbie';
import {Timeline} from '../components/Timeline';
import {InstalmentPlanMenu} from '../components/InstalmentPlanMenu';

import {
  InstalmentPlan,
  InstalmentPlanMode,
  PaymentMethodStatus,
  useGetInstalmentPlanQuery,
} from 'lib/graphql/API';
import {getBillerSlugFromUrl} from 'lib/url';
import {navigate} from 'lib/navigation/routes';
import {PaymentMethodCompact} from '../components/PaymentMethodCompact';
import {useActor} from '@xstate/react';
import * as Auth from 'lib/auth/Auth';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {getInstalmentPlanHeading} from 'features/instalment-plan/getInstalmentPlanHeading';
import {capitalize, formatToDollars, toTitleCase} from 'payble-shared';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';

export const View: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const slug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(slug);

  useEffect(() => {
    document.title = 'Payble - Payment Plan';
    authService.send('REFRESH_CONTACT');
  }, []);

  const billerConfig = useBillerConfig();

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  const {loading, error, data} = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  // Handle loading states
  if (
    loading ||
    authState.matches({
      authenticated: 'refreshing',
    })
  ) {
    return <Loading />;
  }

  if (error)
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops! Payment plan not found!"
        debbieMessage="It seems like something went wrong with the plan you're looking for. Don't worry we got you. You can click below to go back home and then look for all the plans available."
        onClick={() => {
          navigate('/biller/:slug', {slug});
        }}
        buttonText="Go Home"
      />
    );
  if (!data || !data.instalmentPlan || !data.instalmentPlan.id)
    return <>No payment plan found</>;

  const currentPaymentMethod = authState.context.contact?.paymentMethods?.find(
    p => p.id === data.instalmentPlan?.paymentMethodId
  );

  const isPaymentMethodActive =
    currentPaymentMethod?.status === PaymentMethodStatus.Active;

  const instalmentPlanHeading = getInstalmentPlanHeading(
    data.instalmentPlan as InstalmentPlan
  );

  const isConsumerFacingPlan =
    data.instalmentPlan.mode !== InstalmentPlanMode.PayOnY;

  return (
    <div className="flex flex-1 flex-col h-full w-full overflow-hidden">
      <div className="flex flex-1 h-full max-w-xl md:w-xl m-auto z-10">
        <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie
              title={`Manage your ${instalmentPlanHeading} ${
                isConsumerFacingPlan
                  ? `plan for #${data.instalmentPlan.account?.externalId}`
                  : `for #${data.instalmentPlan.account?.externalId}`
              }`}
              message={`You can track and manage your ${instalmentPlanHeading} plan here`}
            />

            <div className="flex  mb-2 mt-5">
              <div className="flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol
                    role="list"
                    className="bg-white rounded-md shadow px-6 flex space-x-4"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <button
                          onClick={() => navigate('/biller/:slug', {slug})}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-6 h-full text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          onClick={() =>
                            navigate(
                              '/biller/:slug/instalment-plan/:instalmentPlanId',
                              {slug, instalmentPlanId}
                            )
                          }
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          Payment plan
                        </button>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="flex pl-4">
                <InstalmentPlanMenu
                  slug={slug}
                  instalmentPlan={data.instalmentPlan}
                  isPaymentMethodActive={isPaymentMethodActive}
                  canUpdateInstalmentAmount={
                    data.instalmentPlan.canUpdateInstalmentAmount
                  }
                />
              </div>
            </div>

            <div className="shadow overflow-hidden sm:rounded-md mt-5">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {`${toTitleCase(instalmentPlanHeading)} ${
                      data.instalmentPlan.account.externalId
                    }`}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{`${capitalize(
                    billerConfig.getAccountTypeTitle(
                      data.instalmentPlan.account.type
                    )
                  )} for ${data.instalmentPlan.account.description}`}</p>
                </div>
                <div className="mt-4 grid grid-cols-3 gap-y-2 gap-x-4 mb-8 text-sm">
                  {currentPaymentMethod && (
                    <>
                      <div className="font-semibold text-gray-500 relative top-2">
                        Payment Method
                      </div>
                      <div className="col-span-2 relative">
                        <PaymentMethodCompact
                          paymentMethod={currentPaymentMethod}
                        />
                        {!isPaymentMethodActive && (
                          <button
                            onClick={() => {
                              if (data.instalmentPlan) {
                                navigate(
                                  '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
                                  {
                                    instalmentPlanId: data.instalmentPlan.id,
                                    slug,
                                  }
                                );
                              }
                            }}
                            className="absolute -right-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-lg text-xs top-1 focus:outline-none focus:ring-offset-2 border-transparent focus:ring-2 focus:ring-gray-200"
                          >
                            Change
                          </button>
                        )}
                      </div>
                    </>
                  )}
                  {data.instalmentPlan.frequency && (
                    <>
                      <div className="font-semibold text-gray-500">
                        Frequency
                      </div>
                      <div className="col-span-2">
                        {toTitleCase(data.instalmentPlan.frequency)}
                      </div>
                    </>
                  )}
                  <div className="font-semibold text-gray-500">Total</div>
                  <div className="col-span-2">
                    <NumberFormat
                      value={formatToDollars(data?.instalmentPlan.amount)}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                    />
                  </div>
                  <div className="font-semibold text-gray-500">Paid</div>
                  <div className="col-span-2">
                    <NumberFormat
                      value={formatToDollars(data?.instalmentPlan.amountPaid)}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                    />
                  </div>
                  <div className="font-semibold text-gray-500">Remaining</div>
                  <div className="col-span-2 relative">
                    <NumberFormat
                      value={formatToDollars(data?.instalmentPlan.amountDue)}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                    />
                    <button
                      disabled={data.instalmentPlan.instalments.length === 0}
                      onClick={() =>
                        navigate(
                          '/biller/:slug/instalment-plan/:instalmentPlanId/schedule',
                          {slug, instalmentPlanId}
                        )
                      }
                      className="absolute -right-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-lg text-xs -top-1 focus:outline-none focus:ring-offset-2 border-transparent focus:ring-2 focus:ring-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      See more
                    </button>
                  </div>
                </div>

                <h3 className="text-base leading-6 font-medium text-gray-900 mb-0 border-b-2 pb-3 border-b-gray-100">
                  Activity
                </h3>
                <RolloverNotice
                  showNotice={
                    hasYearlyRollOver && !!data.instalmentPlan?.completedAt
                  }
                />
                <Timeline
                  instalmentPlan={data.instalmentPlan}
                  isPaymentMethodActive={isPaymentMethodActive}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
