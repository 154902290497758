import 'react-datepicker/dist/react-datepicker.css';
import {classNames} from 'lib/styles';
import React from 'react';
import {AbsoluteDate} from 'payble-shared';
import {CalendarIcon} from '@heroicons/react/20/solid';
interface Props {
  minDate: AbsoluteDate;
  maxDate: AbsoluteDate;

  error?: Error;
  setError: (error?: Error) => void;

  value?: AbsoluteDate;
  onChange: (date: AbsoluteDate) => void;
}
export const DatePicker = (props: Props) => {
  const {onChange, minDate, maxDate, value, setError, error} = props;

  const onSetStartDate = (date: AbsoluteDate | null) => {
    setError(undefined);

    if (date === null) {
      setError(new Error('Start date is required'));
      return;
    }
    if (date.isBefore(minDate)) {
      setError(
        new Error(
          `Start date cannot be before ${minDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }
    if (date.isAfter(maxDate)) {
      setError(
        new Error(
          `Start date cannot be after ${maxDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }

    onChange(date);
  };

  return (
    <>
      <div className="relative">
        <input
          id="startDate"
          name="startDate"
          type="date"
          min={minDate.toISO()}
          max={maxDate.toISO()}
          className="w-full text-center transition bg-white border-transparent rounded-md shadow-sm hover:border-blue-500 border-1 [&::-webkit-calendar-picker-indicator]:opacity-0"
          defaultValue={value?.toISO()}
          onChange={e =>
            onSetStartDate(AbsoluteDate.maybeFromISO(e.target.value))
          }
        />
        <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
          <CalendarIcon className="w-4 h-4 text-black" />
        </div>
      </div>
      <div
        className={classNames(
          'mt-2 text-sm text-red-600 text-center transition',
          error ? 'opacity-100' : 'opacity-0'
        )}
      >
        {error ? error.message : ''}
      </div>
    </>
  );
};
