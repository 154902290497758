import React from 'react';
import {XMarkIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';
import {Form, Formik} from 'formik';
import {
  GetAccountByExternalIdAnonymouslyQuery,
  useGetAccountByExternalIdAnonymouslyLazyQuery,
} from 'lib/graphql/publicAPI';
import {getBillerFriendlyNameFromSlug, getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {LookupAccountExternalIdInput} from 'features/setup/components/LookupAccountExternalIdInput';
import {LookupAccountSubmitButton} from 'features/setup/components/LookupAccountSubmitButton';
import {ApolloError} from '@apollo/client';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {useInfringementParams} from '../hooks/useInfringementParams';
import {getBillerAccountTypeInfo, possessive} from 'payble-shared';
import {NotFound} from 'features/navigation/NotFound';

type FormValue = {
  accountExternalId?: string;
};

function getErrorMessage(
  error?: ApolloError,
  data?: GetAccountByExternalIdAnonymouslyQuery
) {
  if (error) {
    return error?.message;
  }
  if (data && data.accountByExternalIdAnonymously === null) {
    return 'Record not found';
  }

  return '';
}

export const LookupAccount = () => {
  const navigate = useInfringementNavigate();
  const {accountType, accountExternalId} = useInfringementParams();
  const billerSlug = getBillerSlugFromUrl();
  const accountTypeInfo = getBillerAccountTypeInfo(billerSlug, accountType);
  const [getAccount, {loading, data, error}] =
    useGetAccountByExternalIdAnonymouslyLazyQuery();
  const friendlyName = getBillerFriendlyNameFromSlug(billerSlug);

  async function onSubmit({accountExternalId: externalId}: FormValue) {
    if (externalId) {
      const {data} = await getAccount({
        variables: {
          type: accountType,
          externalId,
          billerSlug,
        },
      });

      if (data?.accountByExternalIdAnonymously?.id) {
        navigate('/confirm-payment', {
          accountType,
          accountExternalId: data?.accountByExternalIdAnonymously?.externalId,
        });
      }
    }
  }

  if (!accountTypeInfo) return <NotFound />;

  if (loading) return <Loading />;

  const errorMessage = getErrorMessage(error, data);

  return (
    <div className="relative h-full max-w-2xl px-4 py-6 mx-auto sm:px-6 lg:max-w-7xl lg:py-8 lg:px-8">
      <Formik<FormValue>
        initialValues={{
          accountExternalId,
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form className="flex flex-col gap-4">
            <Debbie
              title={`Welcome to ${possessive(
                friendlyName
              )} infringement payment system`}
              message="Enter your infringement number to easily pay or manage your account."
            />
            <div>
              <LookupAccountExternalIdInput
                accountTypeInfo={accountTypeInfo}
                disabled={loading}
              />
              {errorMessage ? (
                <div className="flex items-center">
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                  <span className="mt-1 ml-1 text-sm text-gray-400">
                    {errorMessage}
                  </span>
                </div>
              ) : null}
            </div>
            <LookupAccountSubmitButton cta="Lookup infringement" />
          </Form>
        )}
      </Formik>
    </div>
  );
};
