import {Debbie} from 'components/organisms/Debbie';
import {goBack, navigate as navigateTo} from 'lib/navigation/routes';
import {useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupRoute} from '../components/SetupRoute';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {formatToCents, formatToDollars} from 'payble-shared';
import CurrencyInput from 'react-currency-input-field';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {toSentenceCase} from 'lib/text';
import {InstalmentPlanMode} from 'lib/graphql/API';

type PaymentFrequency = 'weekly' | 'fortnightly' | 'monthly';

const FREQUENCY_MULTIPLIER = {
  weekly: 1,
  fortnightly: 2,
  monthly: 4,
};

const MINIMUM_INSTALMENT_AMOUNT = 1000;

export const SmoothPayAmount = () => {
  const {account} = useSetupRoute();
  const billerSlug = getBillerSlugFromUrl();
  const {
    instalmentStartAt,
    instalmentFrequency,
    amountInCents: paramAmount,
  } = useSetupSearchParams();
  const navigate = useSetupNavigate();
  const {patch} = usePatchSearchParams();
  const [errorMessage, setErrorMessage] = useState({
    risk: '',
    amount: '',
  });
  const {averageWeeklyPayment} = account.meta;

  const defaultAmount = paramAmount
    ? paramAmount
    : typeof averageWeeklyPayment === 'number' && !isNaN(averageWeeklyPayment)
      ? account.meta.averageWeeklyPayment *
        FREQUENCY_MULTIPLIER[instalmentFrequency as PaymentFrequency]
      : 0;

  const amountInCents =
    MINIMUM_INSTALMENT_AMOUNT >= defaultAmount
      ? MINIMUM_INSTALMENT_AMOUNT
      : defaultAmount;

  if (!instalmentStartAt) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage="Missing start date. Please try again."
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie title={`Get ahead by making ${instalmentFrequency} payments`} />

        <div className="grid w-full gap-2 mt-6">
          <div className="space-y-4">
            <h4 className="font-semibold">
              {toSentenceCase(instalmentFrequency)} Payments
            </h4>
            <span className="mb-2 text-sm text-gray-600">
              Enter an amount to pay {instalmentFrequency}
            </span>

            <CurrencyInput
              className={
                'bg-white rounded-md shadow-sm hover:border-blue-500 border-1 w-full transition border-transparent'
              }
              placeholder="Please enter an amount"
              prefix="$"
              allowNegativeValue={false}
              defaultValue={formatToDollars(amountInCents)}
              decimalsLimit={2}
              onValueChange={(value, __name) => {
                const valueAsCents = formatToCents(
                  parseFloat(value !== undefined ? value : '0')
                );

                if (valueAsCents < MINIMUM_INSTALMENT_AMOUNT) {
                  setErrorMessage({
                    ...errorMessage,
                    amount: `Minimum amount is $${formatToDollars(
                      MINIMUM_INSTALMENT_AMOUNT
                    )}`,
                  });

                  return;
                }

                setErrorMessage({...errorMessage, amount: ''});

                patch({
                  amountInCents: valueAsCents.toString(),
                });
              }}
            />
            {errorMessage.amount && (
              <p className="mt-2 text-sm text-red-500">{errorMessage.amount}</p>
            )}
          </div>
        </div>

        <button
          type="button"
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => {
            navigate('/biller/:slug/setup/plan/preview', {
              amountInCents: amountInCents.toString(),
              instalmentMode: InstalmentPlanMode.SmoothPay,
            });
          }}
        >
          Next
        </button>
        <button
          onClick={() => goBack()}
          className="mt-6 text-blue-600 transition hover:text-blue-700"
        >
          Back
        </button>
      </div>
    </div>
  );
};
