import React from 'react';
import {goBack} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {Form, Formik} from 'formik';
import {RadioGroupField} from '../components/RadioGroupField';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

export const OneOffOptions: React.FC = () => {
  const navigate = useSetupNavigate();
  const modes = [
    {
      value: 'PAYMENT_MODE',
      label: 'Pay now',
      description: 'Enter an amount to pay now',
    },
    {
      value: 'SCHEDULE_PAYMENT_MODE',
      label: 'Pay in future',
      description: 'Enter an amount to pay in the future',
    },
  ];

  return (
    <Formik
      initialValues={{
        mode: modes[0].value,
      }}
      onSubmit={({mode}) => {
        navigate('/biller/:slug/setup/one-off/amount', {mode});
      }}
    >
      <Form className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title="When do you want to make a payment?" />
          <div className="flex flex-col items-center justify-center flex-1 h-full">
            <RadioGroupField name="mode" options={modes} />
            <button
              type="submit"
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
            <button
              onClick={goBack}
              className="mt-6 text-blue-600 transition hover:text-blue-700"
            >
              Back
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
