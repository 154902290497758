export const HowToVideoData = [
  {
    title: 'Login to Payble',
    cloudfareStreamVideoID: '5b10b7bd37f0eda3644f23f3bba55c10',
    videoLink:
      'https://iframe.cloudflarestream.com/5b10b7bd37f0eda3644f23f3bba55c10?preload=metadata',
    htmlId: 'login',
  },
  {
    title: 'Setting up a flexible payment plan',
    cloudfareStreamVideoID: '16cba53583772814455c3345dab1aec5',
    videoLink:
      'https://iframe.cloudflarestream.com/16cba53583772814455c3345dab1aec5?preload=metadata',
    htmlId: 'setup-plan',
  },
  {
    title: 'Manage your payment plan',
    cloudfareStreamVideoID: '8379649a1ba9f8e664e1b7c6a27474dc',
    videoLink:
      'https://iframe.cloudflarestream.com/8379649a1ba9f8e664e1b7c6a27474dc?preload=metadata',
    htmlId: 'manage-plan',
  },
  {
    title: 'Skip a scheduled payment',
    cloudfareStreamVideoID: '437d245606b533cdaa8a7cee589528f6',
    videoLink:
      'https://iframe.cloudflarestream.com/437d245606b533cdaa8a7cee589528f6?preload=metadata',
    htmlId: 'skip-instalment',
  },
  {
    title: 'Cancelling an payment plan',
    cloudfareStreamVideoID: '6c28662e04a5ad52378d30a069a5512b',
    videoLink:
      'https://iframe.cloudflarestream.com/6c28662e04a5ad52378d30a069a5512b?preload=metadata',
    htmlId: 'cancel-plan',
  },
];
