import React, {useContext, useEffect} from 'react';
import {useActor} from '@xstate/react';
import {getBillerSlugFromUrl} from 'lib/url';
import * as Auth from 'lib/auth/Auth';
import {navigate} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {DateTime} from 'luxon';
import {HomeMenu} from './HomeMenu';
import {classNames} from 'lib/styles';
import {toSentenceCase} from 'lib/text';
import NumberFormat from 'react-number-format';
import {getFriendlyPaymentStatus} from 'lib/status';
import {PaymentMethodCompact} from 'features/instalment-plan/components/PaymentMethodCompact';
import storage from 'local-storage-fallback';
import {getInstalmentPlanHeading} from 'features/instalment-plan/getInstalmentPlanHeading';
import {formatToDollars, getOptions, toTitleCase} from 'payble-shared';
import {Loading} from 'components/atoms/Loading';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useSearchParams} from 'react-router-dom';
import {useSetupSearchParams} from 'features/setup/hooks/useSetupSearchParams';
import {Payment} from 'lib/graphql/API';

const PaymentOutCome: React.FC<{payment: Payment}> = ({payment}) => {
  if (payment.status !== 'succeeded' && payment.status !== 'failed') {
    return null;
  }
  const billerConfig = useBillerConfig();

  return (
    <>
      <div className="col-span-1 text-base font-semibold text-gray-500">
        {payment.status === 'succeeded' ? 'Paid At' : 'Failed At'}
      </div>
      <div className="col-span-2">
        {(!!payment.succeededAt || !!payment.failedAt) &&
          DateTime.fromISO(
            payment.status === 'succeeded'
              ? payment.succeededAt ?? ''
              : payment.failedAt ?? '',
            getOptions({billerConfig})
          ).toFormat('dd/MM/yyyy')}
      </div>
    </>
  );
};

export const HomeV1: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerConfig = useBillerConfig();
  const [searchParams] = useSearchParams();
  const {accountType} = useSetupSearchParams();

  useEffect(() => {
    authService.send('REFRESH_CONTACT');
  }, []);

  if (!authState.matches({authenticated: 'idle'})) {
    return <Loading />;
  }

  const {instalmentPlans, payments} = authState.context;

  if (!instalmentPlans.length && !payments.length) {
    navigate(
      accountType === 'infringements'
        ? '/biller/:slug/setup/link-contact/infringement'
        : '/biller/:slug/setup',
      {slug: billerSlug},
      true,
      searchParams
    );
    return <></>;
  }

  const pastPaymentsMarkUp = !payments
    ? []
    : payments
        .filter(
          payment =>
            payment.mode === 'payInFull' || payment.mode === 'payAmount'
        )
        .sort(payment => DateTime.fromISO(payment.createdAt ?? '').toMillis())
        .map(payment => {
          return (
            <div
              key={payment.id}
              className="pt-2 mt-2 border-t-2 border-t-slate-50"
            >
              <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                <div className="col-span-1 text-sm font-semibold text-gray-500 sm:text-base">
                  {payment.account?.type === 'water'
                    ? `Water ${billerConfig.getExternalIdLabel(accountType)}`
                    : billerConfig.getExternalIdLabel(accountType)}
                </div>
                <div className="col-span-2 text-sm sm:text-base">
                  {payment.account?.externalId}
                </div>
                <div className="col-span-1 text-sm font-semibold text-gray-500 sm:text-base">
                  Description
                </div>
                <div className="col-span-2 text-sm sm:text-base">
                  {payment.account?.description}
                </div>
                <div className="col-span-1 text-sm font-semibold text-gray-500 sm:text-base">
                  Amount
                </div>
                <div className="col-span-2">
                  <NumberFormat
                    value={formatToDollars(payment.amount)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalSeparator={'.'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                  />
                </div>
                <PaymentOutCome payment={payment} />
                <div className="col-span-1 text-sm font-semibold text-gray-500 sm:text-base">
                  Status
                </div>
                <div className="col-span-2">
                  <div
                    className={classNames(
                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
                      payment.status === 'succeeded'
                        ? 'bg-green-100 text-green-800'
                        : '',
                      payment.status === 'processing'
                        ? 'bg-gray-100 text-gray-800'
                        : '',
                      payment.status === 'failed'
                        ? 'bg-red-100 text-red-800'
                        : '',
                      payment.status === 'cancelled'
                        ? 'bg-yellow-100 text-yellow-800'
                        : ''
                    )}
                  >
                    {getFriendlyPaymentStatus(payment.status)}
                  </div>
                </div>
                <div className="flex col-span-3 mt-2">
                  <button
                    className="items-center justify-center flex-1 px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() =>
                      navigate('/biller/:slug/payment/:paymentId', {
                        slug: billerSlug,
                        paymentId: payment.id,
                      })
                    }
                  >
                    View details
                  </button>
                </div>
              </div>
            </div>
          );
        });

  const activeInstalmentPlans = instalmentPlans.filter(
    instalmentPlan =>
      instalmentPlan.status !== 'cancelled' &&
      instalmentPlan.status !== 'completed'
  );

  const currentInstalmentPlansMarkUp = !instalmentPlans
    ? []
    : activeInstalmentPlans.map(instalmentPlan => {
        const currentPaymentMethod =
          authState.context.contact?.paymentMethods?.find(
            p => p.id === instalmentPlan.paymentMethodId
          );

        const instalmentPlanHeading = getInstalmentPlanHeading(instalmentPlan);

        return (
          <div
            key={instalmentPlan.id}
            className="pt-2 mt-2 border-t-2 border-t-slate-50"
          >
            <div className="grid grid-cols-5 gap-x-4 gap-y-1">
              {currentPaymentMethod && (
                <>
                  <div className="col-span-2 text-sm sm:text-base font-semibold text-gray-500 relative top-[6px]">
                    Payment method
                  </div>
                  <div className="col-span-3">
                    <PaymentMethodCompact
                      paymentMethod={currentPaymentMethod}
                    />
                  </div>
                </>
              )}

              <div className="col-span-2 text-sm font-semibold text-gray-500 sm:text-base">
                {instalmentPlan.account.type === 'water'
                  ? `Water ${billerConfig.getExternalIdLabel(instalmentPlan.account.type)}`
                  : billerConfig.getExternalIdLabel(
                      instalmentPlan.account.type
                    )}
              </div>
              <div className="col-span-3 text-sm sm:text-base">
                {instalmentPlan.account.externalId}
              </div>
              <div className="col-span-2 text-sm font-semibold text-gray-500 sm:text-base">
                Description
              </div>
              <div className="col-span-3 text-sm sm:text-base">
                {instalmentPlan.account.description}
              </div>
              <div className="col-span-2 text-base font-semibold text-gray-500">
                Plan type
              </div>
              <div className="col-span-3">
                {toSentenceCase(instalmentPlanHeading)}
              </div>
              <div className="col-span-2 text-sm font-semibold text-gray-500 sm:text-base">
                Next due
              </div>
              <div className="col-span-3 text-sm sm:text-base">
                {instalmentPlan.nextInstalmentDueAt
                  ?.toDateTime({billerConfig})
                  .toFormat('dd/MM/yyyy') ?? ''}
              </div>
              <div className="col-span-2 text-sm font-semibold text-gray-500 sm:text-base">
                Status
              </div>
              <div className="col-span-3">
                <div
                  className={classNames(
                    'inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
                    instalmentPlan.status === 'completed'
                      ? 'bg-green-100 text-green-800'
                      : '',
                    instalmentPlan.status === 'onTrack'
                      ? 'bg-green-100 text-green-800'
                      : '',
                    instalmentPlan.status === 'pending'
                      ? 'bg-gray-100 text-gray-800'
                      : '',
                    instalmentPlan.status === 'processing'
                      ? 'bg-gray-100 text-gray-800'
                      : '',
                    instalmentPlan.status === 'overdue'
                      ? 'bg-red-100 text-red-800'
                      : '',
                    instalmentPlan.status === 'cancelled'
                      ? 'bg-yellow-100 text-yellow-800'
                      : ''
                  )}
                >
                  {toTitleCase(instalmentPlan.status as string).replace(
                    'track',
                    ' track'
                  )}
                </div>
              </div>
            </div>
            <div className="flex mt-2">
              <button
                className="items-center justify-center flex-1 px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() =>
                  navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
                    slug: billerSlug,
                    instalmentPlanId: instalmentPlan.id,
                  })
                }
              >
                View details
              </button>
            </div>
          </div>
        );
      });

  const pastInstalmentPlansMarkUp = !instalmentPlans
    ? []
    : instalmentPlans
        .filter(
          instalmentPlan =>
            instalmentPlan.status === 'cancelled' ||
            instalmentPlan.status === 'completed'
        )
        .map(instalmentPlan => {
          const instalmentPlanHeading =
            getInstalmentPlanHeading(instalmentPlan);

          return (
            <div
              key={instalmentPlan.id}
              className="pt-2 mt-2 border-t-2 border-t-slate-50"
            >
              <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                <div className="col-span-1 text-base font-semibold text-gray-500">
                  {instalmentPlan.account.type === 'water'
                    ? `Water ${billerConfig.getExternalIdLabel(accountType)}`
                    : billerConfig.getExternalIdLabel(accountType)}
                </div>
                <div className="col-span-2">
                  {instalmentPlan.account.externalId}
                </div>
                <div className="col-span-1 text-base font-semibold text-gray-500">
                  Description
                </div>
                <div className="col-span-2">
                  {instalmentPlan.account.description}
                </div>
                <div className="col-span-1 text-base font-semibold text-gray-500">
                  Plan type
                </div>
                <div className="col-span-2">
                  {toSentenceCase(instalmentPlanHeading)}
                </div>
                <div className="col-span-1 text-base font-semibold text-gray-500">
                  {instalmentPlan.status === 'cancelled'
                    ? 'Cancelled At'
                    : 'Completed At'}
                </div>
                <div className="col-span-2">
                  {DateTime.fromISO(
                    instalmentPlan.status === 'cancelled'
                      ? instalmentPlan.cancelledAt ?? ''
                      : instalmentPlan.completedAt ?? '',
                    getOptions({billerConfig})
                  ).toFormat('dd/MM/yyyy')}
                </div>
                <div className="col-span-1 text-base font-semibold text-gray-500">
                  Status
                </div>
                <div className="col-span-2">
                  {' '}
                  <div
                    className={classNames(
                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
                      instalmentPlan.status === 'completed'
                        ? 'bg-green-100 text-green-800'
                        : '',
                      instalmentPlan.status === 'onTrack'
                        ? 'bg-green-100 text-green-800'
                        : '',
                      instalmentPlan.status === 'pending'
                        ? 'bg-gray-100 text-gray-800'
                        : '',
                      instalmentPlan.status === 'processing'
                        ? 'bg-gray-100 text-gray-800'
                        : '',
                      instalmentPlan.status === 'overdue'
                        ? 'bg-red-100 text-red-800'
                        : '',
                      instalmentPlan.status === 'cancelled'
                        ? 'bg-yellow-100 text-yellow-800'
                        : ''
                    )}
                  >
                    {toTitleCase(instalmentPlan.status as string)}
                  </div>
                </div>
              </div>
              <div className="flex mt-2">
                <button
                  className="items-center justify-center flex-1 px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() =>
                    navigate(
                      '/biller/:slug/instalment-plan/:instalmentPlanId',
                      {
                        slug: billerSlug,
                        instalmentPlanId: instalmentPlan.id,
                      }
                    )
                  }
                >
                  View details
                </button>
              </div>
            </div>
          );
        });

  const welcomeMessageString = storage.getItem('signed-up')
    ? 'Welcome'
    : 'Welcome Back';

  return (
    <div className="flex flex-col flex-1 w-full h-full overflow-hidden">
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie
              title={`${welcomeMessageString}, ${authState.context.contact?.givenName}`}
              message="You can track and manage your payment plans here"
            />

            <div className="flex mt-5 mb-2">
              <div className="flex-1">
                <button
                  type="submit"
                  data-testid="home-new-payment-btn"
                  className="items-center disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 h-[44px] "
                  onClick={() => {
                    navigate('/biller/:slug/setup', {slug: billerSlug});
                  }}
                >
                  New payment
                </button>
              </div>
              <div className="flex-none">
                <HomeMenu
                  slug={billerSlug}
                  paymentMethodsOnClick={
                    activeInstalmentPlans.length === 1
                      ? () => {
                          navigate(
                            '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
                            {
                              slug: billerSlug,
                              instalmentPlanId: activeInstalmentPlans[0].id,
                            }
                          );
                        }
                      : undefined
                  }
                />
              </div>
            </div>

            <div className="mt-5 overflow-hidden rounded-md shadow">
              <div className="px-4 py-5 space-y-5 bg-white sm:p-6">
                {currentInstalmentPlansMarkUp.length > 0 && (
                  <div>
                    <h3 className="font-semibold text-center text-gray-800">
                      Active payment plans
                    </h3>
                    {currentInstalmentPlansMarkUp}
                  </div>
                )}
                {pastInstalmentPlansMarkUp.length > 0 && (
                  <div>
                    <h3 className="font-semibold text-center text-gray-800">
                      Past payment plans
                    </h3>
                    {pastInstalmentPlansMarkUp}
                  </div>
                )}
                {pastPaymentsMarkUp && pastPaymentsMarkUp.length > 0 && (
                  <div>
                    <h3 className="font-semibold text-center text-gray-800">
                      One-off payments
                    </h3>
                    {pastPaymentsMarkUp}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
