import React, {useContext, useEffect, useState} from 'react';
import {useActor} from '@xstate/react';
import {useLocation, useParams} from 'react-router';

import * as Auth from 'lib/auth/Auth';
import {getBillerSlugFromUrl} from 'lib/url';
import {
  PaymentMethod,
  useChangeInstalmentPlanPaymentMethodMutation,
  useGetInstalmentPlanQuery,
} from 'lib/graphql/API';

import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';
import {buttonClasses} from 'lib/styles';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {PaymentMethodMenu} from '../components/PaymentMethodMenu';
import {ChoosePaymentMethod} from 'components/organisms/ChoosePaymentMethod';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';

export const ChangePaymentMethod: React.FC = () => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const queryParams = new URLSearchParams(useLocation().search);
  const paymentMethodIdFromParams = queryParams.get('paymentMethodId');
  // Get current payment method so we can show it as selected
  if (billerSlug === '') return <>Cannot load without a biller slug</>;
  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    document.title = 'Payble - Change Payment Method';
  }, []);

  const {
    loading: instalmentPlanLoading,
    error: instalmentPlanError,
    data: instalmentPlanData,
  } = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  const [
    changePaymentMethod,
    {loading: changePaymentMethodLoading, error: changePaymentMethodError},
  ] = useChangeInstalmentPlanPaymentMethodMutation();

  const [paymentMethodId, setPaymentMethodId] = useState(
    instalmentPlanData?.instalmentPlan?.paymentMethodId
  );

  if (
    instalmentPlanLoading ||
    changePaymentMethodLoading ||
    !authState.matches({authenticated: 'idle'})
  )
    return <Loading />;
  if (instalmentPlanError)
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  if (!authState.context.contact)
    return <ErrorMessage message="No contact loaded in session" />;

  const hasPaymentMethodChanged =
    instalmentPlanData?.instalmentPlan?.paymentMethodId !== paymentMethodId &&
    paymentMethodId !== undefined;

  const onChangePaymentMethod = async (
    __e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const {errors} = await changePaymentMethod({
      variables: {
        input: {
          instalmentPlanId,
          paymentMethodId: (paymentMethodIdFromParams || paymentMethodId)!,
        },
      },
    });

    if (!errors) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
        slug: billerSlug,
        instalmentPlanId,
      });
    }
  };

  if (!instalmentPlanData || !instalmentPlanData.instalmentPlan) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops! Something went wrong."
        debbieMessage="Sorry, there's no data found for the payment plan you're looking for. Please try again by clicking below."
        onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
      />
    );
  }

  const selectedPaymentMethodId =
    paymentMethodIdFromParams ||
    instalmentPlanData.instalmentPlan.paymentMethodId;

  return (
    <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie
          title="Choose a new payment method"
          message="Select or add a new method of payment from the options below"
        />
        <div className="flex mb-2 mt-5">
          <div className="flex-1">
            <nav className="flex" aria-label="Breadcrumb">
              <ol
                role="list"
                className="bg-white rounded-md shadow px-6 flex space-x-4"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <button
                      onClick={() =>
                        navigate('/biller/:slug', {slug: billerSlug})
                      }
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </button>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-6 h-full text-gray-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <button
                      onClick={() =>
                        navigate(
                          '/biller/:slug/instalment-plan/:instalmentPlanId',
                          {slug: billerSlug, instalmentPlanId}
                        )
                      }
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      Payment Plan
                    </button>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="flex pl-4">
            <PaymentMethodMenu slug={billerSlug} />
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md mt-5">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="flex flex-col">
              <ChoosePaymentMethod
                paymentMethods={
                  authState.context.contact.paymentMethods ||
                  ([] as PaymentMethod[])
                }
                selectedPaymentMethodId={selectedPaymentMethodId}
                onChange={setPaymentMethodId}
              />
              <div className="my-3 font-base"></div>
              {changePaymentMethodError && (
                <div>
                  <ErrorMessage message={changePaymentMethodError.message} />
                </div>
              )}
              <button
                className={buttonClasses}
                disabled={
                  (!paymentMethodIdFromParams && !hasPaymentMethodChanged) ||
                  changePaymentMethodLoading
                }
                onClick={onChangePaymentMethod}
              >
                {changePaymentMethodLoading && <ButtonSpinner />}
                {paymentMethodIdFromParams
                  ? 'Confirm Payment Method'
                  : 'Change Payment Method'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
