import React, {ReactNode} from 'react';
import {validateNumberInputInRange} from 'lib/validation';
import {Debbie} from 'components/organisms/Debbie';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import {NoticeOnInterest} from '../shared/NoticeOnIinterest';
import {getBillerSlugFromUrl} from 'lib/url';
import {formatToCents, formatToDollars} from 'payble-shared';
import {Form, Formik} from 'formik';

type FormValues = {amountInCents: number};

type Props = {
  debbieTitle: string;
  heading: string;
  description: string;
  minAmount: number;
  maxAmount: number;
  lockedAmount: boolean;
  defaultAmount: number;
  onClickBack: () => void;
  onSubmit: (values: FormValues) => void;
};
export const PaymentAmountForm: React.FC<Props> = ({
  debbieTitle,
  heading,
  description,
  minAmount,
  maxAmount,
  lockedAmount,
  defaultAmount,
  onClickBack,
  onSubmit,
}) => {
  const billerSlug = getBillerSlugFromUrl();

  const normalInputClass =
    'focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md';
  const errorInputClass =
    'ring-red-500 border-red-500 block sm:text-sm border-red-300 rounded-md';

  return (
    <Formik<FormValues>
      initialValues={{
        amountInCents: defaultAmount,
      }}
      onSubmit={onSubmit}
      validate={({amountInCents}) => {
        const errors: {[K in keyof FormValues]?: ReactNode} = {};

        if (!amountInCents) {
          errors.amountInCents = 'Please enter a amount greater than zero';
          return errors;
        }

        const {
          valid,
          message = '',
          value = 0,
        } = validateNumberInputInRange(amountInCents, minAmount, maxAmount);

        if (!valid) {
          errors.amountInCents = (
            <>
              {message}
              {value > 0 && (
                <>
                  {' '}
                  <NumberFormat
                    value={formatToDollars(value)}
                    displayType={'text'}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </>
              )}
            </>
          );
        }

        return errors;
      }}
    >
      {({touched, errors, setFieldValue}) => (
        <Form className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie title={debbieTitle} />
            <div className="mt-4 overflow-hidden rounded-md shadow">
              <div className="px-4 py-4 bg-white sm:p-6">
                <div className="flex flex-col justify-center flex-1 h-full ">
                  <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
                    {heading}
                  </h3>
                  <p className="mb-2 text-sm text-gray-600">{description}</p>
                  {lockedAmount ? (
                    <NumberFormat
                      className="text-lg"
                      value={formatToDollars(defaultAmount)}
                      displayType={'text'}
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  ) : (
                    <CurrencyInput
                      disabled={lockedAmount}
                      className={
                        errors.amountInCents
                          ? errorInputClass
                          : normalInputClass
                      }
                      placeholder="Please enter an amount"
                      prefix="$"
                      defaultValue={formatToDollars(defaultAmount)}
                      allowNegativeValue={false}
                      decimalsLimit={2}
                      onValueChange={(value, __name) => {
                        setFieldValue(
                          'amountInCents',
                          value ? formatToCents(parseFloat(value)) : null
                        );
                      }}
                    />
                  )}

                  {errors.amountInCents && touched.amountInCents && (
                    <p className="mt-2 text-sm text-red-500">
                      {errors.amountInCents}
                    </p>
                  )}

                  <NoticeOnInterest billerSlug={billerSlug} />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Next
            </button>
            <button
              onClick={onClickBack}
              type="button"
              className="mt-6 text-blue-600 transition hover:text-blue-700"
            >
              Back
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
