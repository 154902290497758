import React, {useEffect} from 'react';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useLinkAccount} from '../hooks/useLinkAccount';
import {getBillerSlugFromUrl} from 'lib/url';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {ActivePlanWarning} from '../components/ActivePlanWarning';
import {ConfirmLinkAccount} from '../components/ConfirmLinkAccount';
import {Loading} from 'components/atoms/Loading';

export const LinkContactPlanRequest: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const {data: account} = useGetAccountFromSearchParams();
  const navigate = useSetupNavigate();
  const {verificationCode, accountType} = useSetupSearchParams();

  const {linkAccount, loading} = useLinkAccount();

  useEffect(() => {
    if (!billerConfig.hasRequestPlan) {
      navigate('/biller/:slug/setup');
    }
  }, [accountType]);

  const onLinkAccount = async () => {
    try {
      await linkAccount({
        accountExternalId: account.externalId,
        accountType: account.type,
        billerSlug,
        verificationCode,
      });
    } catch (e) {
      navigate('/biller/:slug');
      throw e;
    }

    navigate('/biller/:slug/setup/request-plan');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="relative h-full max-w-2xl px-4 py-6 mx-auto sm:px-6 lg:max-w-7xl lg:py-8 lg:px-8">
      <div className="relative flex flex-col h-full"></div>
      <Debbie title="Is this the property you were looking for?" />
      <ActivePlanWarning />
      <ConfirmLinkAccount
        account={account}
        onLinkAccount={onLinkAccount}
        onLinkButtonText="Continue"
      />
    </div>
  );
};
