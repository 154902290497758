import React, {useContext, useEffect, useState} from 'react';
import {useActor} from '@xstate/react';
import * as Auth from 'lib/auth/Auth';
import {ExclamationCircleIcon, HomeIcon} from '@heroicons/react/20/solid';

import {UpdateContactSource, useUpdateContactMutation} from 'lib/graphql/API';
import {getBillerSlugFromUrl} from 'lib/url';
import {buttonClasses, classNames} from 'lib/styles';
import {ProfileMenu} from '../components/ProfileMenu';
import {navigate} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {err, validateEmail} from 'payble-shared';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {StyledSwitchGroup} from '../../setup/components/StyledSwitchGroup';

export const EditProfile: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);

  useEffect(() => {
    document.title = 'Payble - Edit Profile';
  }, []);

  const contact = authState.context.contact;
  if (!contact) return <div>No contact</div>;

  const [email, setEmail] = React.useState<string>(contact.email);
  const [emailError, setEmailError] = React.useState<string>('');
  const [title, setTitle] = React.useState(contact.title);
  const [titleError, __setTitleError] = React.useState<string>('');
  const [givenName, setGivenName] = React.useState(contact.givenName);
  const [givenNameError, __setGivenNameError] = React.useState<string>('');
  const [familyName, setFamilyName] = React.useState(contact.familyName);
  const [familyNameError, __setFamilyNameError] = React.useState<string>('');
  const [sendReceipts, setSendReceipts] = useState<boolean | undefined>();

  const [updateContact, {loading, error}] = useUpdateContactMutation();
  const update = async (__e: React.FormEvent) => {
    if (email !== '') {
      // Validate the email and throw an error if it's not valid
    }

    await updateContact({
      variables: {
        input: {
          email: email === '' ? undefined : email,
          title,
          givenName: givenName === '' ? undefined : givenName,
          familyName: familyName === '' ? undefined : familyName,
          source: UpdateContactSource.Profile,
          sendReceipt: sendReceipts,
        },
      },
    });

    if (!error) {
      navigate('/biller/:slug/profile', {slug: billerSlug});
    }
  };

  // Only enable save button when something has been changed
  const hasChanged =
    contact.email !== email ||
    contact.title !== title ||
    contact.givenName !== givenName ||
    contact.familyName !== familyName ||
    (contact.sendingEmailReceipts !== sendReceipts &&
      sendReceipts !== undefined);

  const hasError =
    emailError !== '' ||
    titleError !== '' ||
    givenNameError !== '' ||
    familyNameError !== '';

  let profileName = 'Your';
  if (authState.context.contact?.givenName) {
    profileName = authState.context.contact.givenName;
    profileName = profileName.endsWith('s')
      ? `${profileName}'`
      : `${profileName}'s`;
  }

  useEffect(() => {
    const result = validateEmail(email);
    if (err(result)) {
      setEmailError(result.message);
    } else {
      setEmailError('');
    }
  }, [email]);

  return (
    <div className="flex flex-1 flex-col h-full w-full overflow-hidden">
      <div className="flex flex-1 h-full max-w-xl md:w-xl m-auto z-10">
        <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie
              title={'Edit your details'}
              message="Update your contact details here. These are used to send communication and match your account."
            />

            <div className="flex  mb-2 mt-5">
              <div className="flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol
                    role="list"
                    className="bg-white rounded-md shadow px-6 flex space-x-4"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            navigate('/biller/:slug', {slug: billerSlug})
                          }
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-6 h-full text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          onClick={() =>
                            navigate('/biller/:slug/profile', {
                              slug: billerSlug,
                            })
                          }
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {profileName} profile
                        </button>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="flex pl-4">
                <ProfileMenu slug={billerSlug} />
              </div>
            </div>

            <div className="shadow overflow-hidden sm:rounded-md mt-5">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Profile Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    We use these details to contact you and link your payments
                    with your account.
                  </p>
                </div>
                {/* Title */}
                <div className="mt-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <select
                      name="title"
                      id="title"
                      className="transition shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      defaultValue={contact.title}
                      onChange={e => setTitle(e.target.value)}
                      disabled={loading}
                    >
                      <option></option>
                      <option>Dr</option>
                      <option>Miss</option>
                      <option>Mr</option>
                      <option>Mrs</option>
                      <option>Ms</option>
                      <option>Mx</option>
                      <option>Prof</option>
                    </select>
                  </div>
                </div>
                {/* Given Name */}
                <div className="mt-4">
                  <label
                    htmlFor="given-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Given Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="given-name"
                      id="given-name"
                      className="transition shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                      defaultValue={contact.givenName}
                      onChange={e => setGivenName(e.target.value)}
                      disabled={loading}
                    />
                  </div>
                  {givenNameError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {givenNameError}
                    </p>
                  )}
                </div>
                {/* Family Name */}
                <div className="mt-4">
                  <label
                    htmlFor="family-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Family Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="family-name"
                      id="family-name"
                      className="transition shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                      defaultValue={contact.familyName}
                      onChange={e => setFamilyName(e.target.value)}
                      disabled={loading}
                    />
                  </div>
                  {familyNameError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {familyNameError}
                    </p>
                  )}
                </div>
                {/* Email */}
                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={classNames(
                        'transition block w-full pr-10  focus:outline-none  sm:text-sm rounded-md',
                        emailError === ''
                          ? 'focus:ring-blue-500 focus:border-blue-500 border-gray-300'
                          : 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                      )}
                      placeholder="you@example.com"
                      defaultValue={contact.email}
                      onChange={e => setEmail(e.target.value)}
                      disabled={loading}
                      aria-invalid="true"
                      aria-describedby="email-error"
                    />
                    <div
                      className={classNames(
                        'transition-opacity absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none opacity-0',
                        emailError && 'opacity-100'
                      )}
                    >
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  {emailError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {emailError}
                    </p>
                  )}
                </div>
                <hr />
                <div className="mt-4">
                  <StyledSwitchGroup
                    checked={sendReceipts ?? contact.sendingEmailReceipts}
                    onChange={() =>
                      setSendReceipts(
                        !(sendReceipts ?? contact.sendingEmailReceipts)
                      )
                    }
                  >
                    Send payment receipts to my email
                  </StyledSwitchGroup>
                </div>
                {/* Save profile */}
                <button
                  type="button"
                  className={classNames(buttonClasses, 'mt-4')}
                  disabled={!hasChanged || hasError || loading}
                  onClick={update}
                >
                  {loading && <ButtonSpinner />}
                  Save profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
