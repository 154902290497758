import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Switch} from '@headlessui/react';

import {getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  GetInstalmentPlanQuery,
  Instalment,
  InstalmentPlanMode,
  useGetInstalmentPlanQuery,
  useSkipInstalmentMutation,
} from 'lib/graphql/API';
import {Debbie} from 'components/organisms/Debbie';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {buttonClasses, classNames} from 'lib/styles';
import NumberFormat from 'react-number-format';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {formatToDollars} from 'payble-shared';

const SkipWarning: React.FC<{
  nextInstalment: Instalment;
  remainingInstalments: Instalment[];
  instalmentPlanData: GetInstalmentPlanQuery | undefined;
}> = ({instalmentPlanData, nextInstalment, remainingInstalments}) => {
  if (!instalmentPlanData?.instalmentPlan?.mode) {
    return <></>;
  }

  switch (instalmentPlanData.instalmentPlan.mode) {
    case InstalmentPlanMode.PayXEveryZ:
      return (
        <>
          I want to skip this next payment, and agree to have a new payment of{' '}
          <NumberFormat
            value={formatToDollars(nextInstalment.amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />{' '}
          scheduled on {nextInstalment.dueAt.toFormat('dd/MM/yyyy')}
        </>
      );
    case InstalmentPlanMode.BalancedPayEveryX: {
      const [nextMilestone] =
        instalmentPlanData.instalmentPlan.account.targetInstalments;

      if (!nextMilestone) {
        throw new Error('No next milestone information.');
      }

      return (
        <>
          I want to skip the next payment, and agree to have the value of{' '}
          <NumberFormat
            value={formatToDollars(nextInstalment.amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />{' '}
          spread evenly over my remaining scheduled payments to{' '}
          {nextMilestone.dueAt.toFormat('dd/MM/yyyy')} date.
        </>
      );
    }
    case InstalmentPlanMode.SmoothPay:
      return (
        <>
          I want to skip my payment on the{' '}
          {nextInstalment.dueAt.toFormat('dd/MM/yyyy')} and agree that no
          payment should be taken on this date. I understand that even if new
          amounts become due this payment will remain skipped.
        </>
      );
    default: {
      return (
        <>
          I want to skip this next payment, and agree to have the value of{' '}
          <NumberFormat
            value={formatToDollars(nextInstalment.amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />{' '}
          spread evenly over my {remainingInstalments?.length} remaining
          scheduled payments.
        </>
      );
    }
  }
};

type Toggles = {
  data: boolean;
};

export const SkipInstalment: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const billerSlug = getBillerSlugFromUrl();

  useEffect(() => {
    document.title = 'Payble - Skip Next Payment';
  }, []);

  if (!instalmentPlanId) {
    return <>Cannot load payment plan without an ID</>;
  }

  if (billerSlug === '') {
    return <>Cannot load without a biller slug</>;
  }

  const {
    loading: instalmentPlanLoading,
    error: instalmentPlanError,
    data: instalmentPlanData,
  } = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  const [
    skipInstalment,
    {loading: skipInstalmentLoading, error: skipInstalmentError},
  ] = useSkipInstalmentMutation();

  const [toggles, setToggles] = useState<Toggles>({
    data: false,
  });

  const nextInstalment = instalmentPlanData?.instalmentPlan?.instalments.find(
    x => x.status === 'overdue' || x.status === 'scheduled'
  );

  if (instalmentPlanLoading) {
    return <Loading />;
  }

  if (!nextInstalment) {
    return <>No payment to make</>;
  }

  const remainingInstalments = instalmentPlanData?.instalmentPlan?.instalments
    .filter(x => x.status === 'overdue' || x.status === 'scheduled')
    .filter(x => x.instalmentId !== nextInstalment?.instalmentId);

  const onToggle = (name: keyof Toggles) => {
    setToggles(prev => ({
      ...toggles,
      [name]: !prev[name],
    }));
  };

  const canSkipInstalment =
    Object.values(toggles).every(Boolean) &&
    instalmentPlanData?.instalmentPlan?.status !== 'cancelled' &&
    instalmentPlanData?.instalmentPlan?.status !== 'completed' &&
    instalmentPlanData?.instalmentPlan?.status !== 'processing';

  const onSkipInstalment = async () => {
    await skipInstalment({
      variables: {
        input: {
          instalmentPlanId,
          instalmentId: nextInstalment.instalmentId,
        },
      },
    });

    if (!skipInstalmentError) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
        slug: billerSlug,
        instalmentPlanId,
      });
    }
  };

  if (instalmentPlanError)
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );

  return (
    <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie
          title={'Skip your next payment'}
          message={
            instalmentPlanData?.instalmentPlan?.mode ===
            InstalmentPlanMode.PayEveryX
              ? "To keep your plan on track, we'll spread the skipped amount evenly across your future scheduled payments."
              : ''
          }
        />

        <nav className="flex mb-2 mt-5" aria-label="Breadcrumb">
          <ol
            role="list"
            className="bg-white rounded-md shadow px-6 flex space-x-4"
          >
            <li className="flex">
              <div className="flex items-center">
                <button
                  onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </button>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <button
                  onClick={() =>
                    navigate(
                      '/biller/:slug/instalment-plan/:instalmentPlanId',
                      {slug: billerSlug, instalmentPlanId}
                    )
                  }
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  Payment Plan
                </button>
              </div>
            </li>
          </ol>
        </nav>

        <div className="shadow overflow-hidden sm:rounded-md mt-5">
          <div className="px-4 py-5 bg-white sm:p-6">
            <Switch.Group
              as="div"
              className="col-span-6 sm:flex items-center justify-between"
            >
              <span className="flex-grow flex flex-col">
                <Switch.Description
                  as="span"
                  className="text-base text-gray-800 mr-2"
                >
                  <SkipWarning
                    nextInstalment={nextInstalment}
                    remainingInstalments={remainingInstalments ?? []}
                    instalmentPlanData={instalmentPlanData}
                  />
                </Switch.Description>
              </span>
              <span className="relative bottom-1 text-blue-600 font-bold sm:hidden">
                {' '}
                I Understand{' '}
              </span>
              <Switch
                data-test-id="skip"
                checked={toggles.data}
                onChange={() => onToggle('data')}
                className={classNames(
                  toggles.data ? 'bg-blue-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  className={classNames(
                    toggles.data ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                >
                  <span
                    className={classNames(
                      toggles.data
                        ? 'opacity-0 ease-out duration-100'
                        : 'opacity-100 ease-in duration-200',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-gray-400"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      toggles.data
                        ? 'opacity-100 ease-in duration-200'
                        : 'opacity-0 ease-out duration-100',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-blue-600"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </Switch.Group>

            <div className="my-3 font-base"></div>
            {skipInstalmentError && (
              <div>
                <ErrorMessage message={skipInstalmentError.message} />
              </div>
            )}
            <button
              className={buttonClasses}
              disabled={!canSkipInstalment || skipInstalmentLoading}
              onClick={onSkipInstalment}
            >
              {skipInstalmentLoading && <ButtonSpinner />}
              Skip next payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
