import React, {useContext, useEffect} from 'react';
import {useActor} from '@xstate/react';
import NumberFormat from 'react-number-format';

import {Debbie} from 'components/organisms/Debbie';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';

import * as Auth from 'lib/auth/Auth';
import {Contact, PaymentStatus, useGetPaymentQuery} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {PaymentMethodRow} from 'features/instalment-plan/components/PaymentMethodRow';

import _refiner from 'refiner-js';
import {formatToDollars, toTitleCase} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';

type ConfirmationPayInFullProps = {
  billerSlug: string;
  contact: Contact;
  paymentId: string;
  manage: boolean;
};

const getPaymentMessage = (status: PaymentStatus) => {
  if (status === PaymentStatus.Succeeded) {
    return 'Your payment was successful.';
  }

  return `Your payment is ${status}.`;
};

export const ConfirmationPayInFull: React.FC<ConfirmationPayInFullProps> = ({
  billerSlug,
  contact,
  paymentId,
  manage,
}) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerConfig = useBillerConfig();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    if (billerSlug === 'city-of-kingston') {
      _refiner('showForm', 'c580a0c0-0174-11ed-ab64-df297cf88894');
    } else if (billerSlug === 'city-of-exampleton') {
      _refiner('showForm', '2ae93970-0cad-11ed-8aee-798d80f31984');
    } else if (billerSlug === 'moreton-bay') {
      _refiner('showForm', '78b8d140-8d75-11ed-a49f-cf738a0d2716');
    }
  }, []);

  const {data, loading, error} = useGetPaymentQuery({
    variables: {
      id: paymentId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data?.payment) return <ErrorMessage message="No payment plan" />;

  const biller = authState.context.biller;

  if (!biller) return <div>No contact or biller</div>;

  const paymentMethod = contact.paymentMethods?.find(
    x => x.id === data.payment?.paymentMethodId
  );

  const debbieTitle = manage
    ? `View payment for #${data.payment.account?.externalId}`
    : `All done ${contact.givenName}!`;

  const debbieMessage = manage
    ? `Review details for ${data.payment.status} payment`
    : `${getPaymentMessage(
        data.payment.status
      )} You'll receive a receipt via SMS.`;

  return (
    <div
      className="flex flex-1 flex-col h-full w-full overflow-hidden p-4 max-w-xl m-auto"
      data-testid="payInFullConfirmation"
    >
      <Debbie title={debbieTitle} message={debbieMessage} />
      <div className="shadow overflow-hidden rounded-md mt-5">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm text-gray-600">
            <div className="border-b pb-4 md:col-span-2">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                data-testid="header"
              >
                Payment {data?.payment?.account?.externalId}
                {data?.payment?.status === 'failed' && (
                  <span className="text-red-600"> (Failed)</span>
                )}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {toTitleCase(data?.payment?.account?.type as string)} for <br />{' '}
                {data?.payment?.account?.description}
              </p>
            </div>
            <div className="">
              <dt className="font-medium text-gray-900 mb-2">
                Contact Information
              </dt>
              <dd>
                <address className="not-italic">
                  <span className="block break-words">
                    {contact.givenName} {contact.familyName}
                  </span>
                  <span className="block">
                    <PhoneNumberFormat
                      value={contact.mobile}
                      region={billerConfig.region}
                    />
                  </span>
                  <span className="block break-words">{contact.email}</span>
                </address>
              </dd>
            </div>
            {paymentMethod && (
              <div className="">
                <dt className="font-medium text-gray-900 mb-2">
                  Payment Information
                </dt>
                <PaymentMethodRow paymentMethod={paymentMethod} />
              </div>
            )}
          </div>
          <dl className="text-sm font-medium text-gray-500 space-y-6 mt-4">
            <div className="flex items-center justify-between border-t  text-gray-900 pt-2">
              <dt className="text-base">Total</dt>
              <dd className="text-base">
                {data?.payment?.status === 'failed' && (
                  <span className="text-red-600">(Failed) </span>
                )}
                <NumberFormat
                  value={formatToDollars(data.payment.amount)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="flex-1 flex items-center">
        <button
          onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
          className="disabled:opacity-50 disabled:cursor-not-allowed mt-4 w-full text-center justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to home
        </button>
      </div>
    </div>
  );
};
