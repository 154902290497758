import {Debbie} from 'components/organisms/Debbie';
import {HomeIcon} from '@heroicons/react/20/solid';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {navigate} from 'lib/navigation/routes';
import {NotificationSettingTable} from 'payble-app-shared/src/components/NotificationSettingTable';
import {useAPIMutation, useAPIQuery} from 'lib/api';

export const NotificationSetting = () => {
  const billerConfig = useBillerConfig();
  const {mutateAsync: update} = useAPIMutation('updateNotificationSettings');
  const {
    data: defaultValues,
    dataUpdatedAt,
    isPending,
  } = useAPIQuery('getNotificationSettings', {query: {enabled: true}});

  return (
    <div className="flex flex-1 flex-col h-full w-full overflow-hidden">
      <div className="flex flex-1 h-full max-w-xl md:w-xl m-auto z-10">
        <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie
              title={'Manage your SMS and email preferences'}
              message="Some of the messages you get are optional. You can adjust these here, or turn them off altogether."
            />

            <div className="flex  mb-2 mt-5">
              <div className="flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol
                    role="list"
                    className="bg-white rounded-md shadow px-6 flex space-x-4"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            navigate('/biller/:slug', {
                              slug: billerConfig.billerSlug,
                            })
                          }
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-6 h-full text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          onClick={() =>
                            navigate('/biller/:slug/notification', {
                              slug: billerConfig.billerSlug,
                            })
                          }
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          Notification settings
                        </button>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="flex pl-4"></div>
            </div>

            <div className="shadow overflow-hidden sm:rounded-md mt-5">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Notification settings
                  </h3>
                  <p className="mt-1 mb-4 text-sm text-gray-500">
                    Manage your none critical notifications
                  </p>
                  <NotificationSettingTable
                    defaultValues={defaultValues}
                    key={dataUpdatedAt}
                    disabled={isPending}
                    onSave={update}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
