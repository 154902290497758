import React, {ReactChild, useState} from 'react';
import {Debbie} from 'components/organisms/Debbie';
import {classNames} from 'lib/styles';
import {getBillerSlugFromUrl} from 'lib/url';
import {NoticeOnInterest} from './NoticeOnIinterest';
import {AbsoluteDate} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

type Props = {
  debbieTitle: string;
  description: ReactChild;
  startAt?: AbsoluteDate;
  onClickNext: (startDate: AbsoluteDate) => void;
  onClickBack: () => void;
  scheduleMaxDays: number;
};
export const PaymentSchedule: React.FC<Props> = ({
  debbieTitle,
  description,
  startAt,
  onClickNext,
  onClickBack,
  scheduleMaxDays,
}) => {
  const billerConfig = useBillerConfig();
  const today = AbsoluteDate.today({billerConfig});

  const [startDate, setStartDate] = useState<AbsoluteDate | undefined>(startAt);
  const [endDate, __setEndDate] = useState<AbsoluteDate>(() =>
    today.plus({days: scheduleMaxDays})
  );
  const [dateError, setDateError] = useState<Error | undefined>(undefined);
  const billerSlug = getBillerSlugFromUrl();

  const onSetStartDate = (date: AbsoluteDate | null) => {
    setDateError(undefined);

    const minStartDate = today;
    const maxEndDate = endDate;
    if (date === null) {
      setDateError(new Error('Start date is required'));
      return;
    }
    if (date.isBefore(minStartDate)) {
      setDateError(
        new Error(
          `Start date cannot be before ${minStartDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }
    if (date.isAfter(maxEndDate)) {
      setDateError(
        new Error(
          `Start date cannot be after ${maxEndDate.toFormat('dd/MM/yyyy')}`
        )
      );
      return;
    }

    setStartDate(date);
  };

  const dateInputClass = !dateError?.message
    ? 'border-gray-300 focus:ring-blue-500 focus:border-blue-500 '
    : 'ring-red-500 border-red-500 border-red-300';

  return (
    <>
      <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title={debbieTitle} />
          <div className="mt-4 overflow-hidden rounded-md shadow">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="flex flex-col justify-center flex-1 h-full ">
                <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
                  Select payment date
                </h3>
                <p className="mb-2 text-sm text-gray-600">{description}</p>
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  min={today.toISO()}
                  max={endDate.toISO()}
                  className={classNames(
                    'block sm:text-sm rounded-md',
                    dateInputClass
                  )}
                  defaultValue={startDate?.toISO()}
                  onChange={e =>
                    onSetStartDate(AbsoluteDate.maybeFromISO(e.target.value))
                  }
                />

                {dateError && (
                  <p className="mt-2 text-sm text-red-500">
                    {dateError.message}
                  </p>
                )}
                <NoticeOnInterest billerSlug={billerSlug} />
              </div>
            </div>
          </div>

          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => startDate && onClickNext(startDate)}
            disabled={!startDate || !!dateError}
          >
            Next
          </button>
          <button
            className="mt-6 text-blue-600 transition hover:text-blue-700"
            onClick={onClickBack}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
