import React from 'react';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';

export function ActivePlanWarning() {
  const account = useGetAccountFromSearchParams();
  const billerSlug = getBillerSlugFromUrl();

  if (account && account?.data?.hasActivePlan) {
    return (
      <div className="rounded-md bg-yellow-50 p-4 my-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Active payment plan
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                There is an active payment plan for this account and you won't
                be able to setup a new plan until that plan is cancelled. If you
                want see your active plans, you can view them on your{' '}
                <a
                  onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
                  className="text-gray-400 hover:text-gray-500 underline cursor-pointer"
                >
                  dashboard
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
