import {
  Account,
  Contact,
  InstalmentPlanMode,
  Payment,
  PaymentMethod,
} from 'lib/graphql/API';
import React, {useContext, useEffect} from 'react';
import _refiner from 'refiner-js';
import * as Auth from 'lib/auth/Auth';
import {navigate} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {toTitleCase} from 'payble-shared';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {ConfirmationPaymentMethodSection} from './ConfirmationPaymentMethodSection';
import {ConfirmationPaymentAmountSection} from './ConfirmationPaymentAmountSection';
import {ConfirmationPaymentDateSection} from './ConfirmationPaymentDateSection';
import {ConfirmationPaymentFrequencySection} from './ConfirmationPaymentFrequencySection';
import {ConfirmationPlanRequestSection} from './ConfirmationPlanRequestSection';
import {CreatedInstalmentPlan, CreatedPlanRequest} from '../graphql/actions';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';

type GetTitle = {
  instalmentPlan: CreatedInstalmentPlan | undefined;
  paymentMethod: PaymentMethod | undefined;
  planRequest: CreatedPlanRequest | undefined;
};
function getTitle({instalmentPlan, paymentMethod, planRequest}: GetTitle) {
  if (planRequest) {
    return 'Your request has been submitted!';
  }

  return instalmentPlan
    ? 'Your payment has been scheduled!'
    : paymentMethod?.card
      ? 'Your payment has been processed!'
      : 'Your payment is processing!';
}
function getRefinerIdForInstalmentPlanMode(
  instalmentPlanMode: InstalmentPlanMode
) {
  switch (instalmentPlanMode) {
    case 'PayXOnY':
      return 'c4e05c50-f379-11ed-b9e8-5bfddfe79cf9';
    case 'PayXEveryZ':
      return '3dc768d0-f37a-11ed-afa8-b7121fa5142b';
    case 'PayOnY':
      return '0e96a290-f37a-11ed-86b2-010b7e063f69';
    default:
      return '6a1d1230-8d75-11ed-9577-1f6ec04f1f1d';
  }
}

function getDetailsHeading(account: Account) {
  if (account.type === 'infringements') return 'Account information';
  return 'Property details';
}

function getDescriptionHeading(account: Account) {
  if (account.type === 'infringements') return 'Description';
  return 'Address';
}

type ConfirmationPresenterProps = {
  account: Account;
  contact: Contact;
  instalmentPlan?: CreatedInstalmentPlan;
  paymentMethod?: PaymentMethod;
  planRequest?: CreatedPlanRequest;
  billerSlug: string;
  payment?: Payment;
  mode: string;
};

export const ConfirmationPresenter: React.FC<ConfirmationPresenterProps> = ({
  account,
  contact,
  billerSlug,
  instalmentPlan,
  paymentMethod,
  planRequest,
  payment,
  mode,
}) => {
  const {authService} = useContext(Auth.Context);
  const billerConfig = useBillerConfig();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    if (instalmentPlan?.mode) {
      _refiner(
        'showForm',
        getRefinerIdForInstalmentPlanMode(instalmentPlan.mode)
      );
    }
  }, []);

  return (
    <div
      id="confirmation"
      className="flex flex-col flex-1 w-full h-full max-w-xl p-4 m-auto overflow-hidden"
    >
      <Debbie title={getTitle({instalmentPlan, planRequest, paymentMethod})} />

      {planRequest && (
        <div className="mt-4 overflow-hidden rounded-md shadow">
          <div className="px-4 py-4 bg-white sm:p-6">
            <p className="text-sm text-gray-600" id="plan-request-info">
              You plan request has been submitted for review, you will hear back
              within the next 5 business days.
            </p>
          </div>
        </div>
      )}

      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="border-b">
            <h4 className="mb-2 font-medium text-gray-900">
              {getDetailsHeading(account)}
            </h4>
            <div className="grid grid-cols-2 gap-4 pb-4">
              <p className="text-sm text-gray-600">
                {billerConfig.accountCategoryLabel}
              </p>
              <p id="notice-type" className="text-sm text-gray-600">
                {toTitleCase(account.type)}
              </p>

              <p className="text-sm text-gray-600">
                {billerConfig.getExternalIdLabel(account.type)}
              </p>
              <p id="external-id" className="text-sm text-gray-600">
                {account.externalId}
              </p>

              <p className="text-sm text-gray-600">
                {getDescriptionHeading(account)}
              </p>
              <p id="address" className="text-sm text-gray-600">
                {account.description}
              </p>
            </div>
          </div>

          <div className="border-b">
            <h4 className="mt-4 mb-2 font-medium text-gray-900">
              Contact information
            </h4>
            <div className="grid grid-cols-2 gap-4 pb-4">
              <p className="text-sm text-gray-600">Name</p>
              <p id="contact-name" className="text-sm text-gray-600">
                {contact.givenName} {contact.familyName}
              </p>

              <p className="text-sm text-gray-600">Mobile number</p>
              <p id="contact-mobile" className="text-sm text-gray-600">
                <PhoneNumberFormat
                  value={contact.mobile}
                  region={billerConfig.region}
                />
              </p>
              <p className="text-sm text-gray-600">Email</p>
              <p
                id="contact-email"
                className="overflow-hidden text-sm text-gray-600"
              >
                {contact.email}
              </p>
            </div>
          </div>

          <div>
            <h4 className="mt-4 mb-2 font-medium text-gray-900">
              Payment information
            </h4>
            <div className="grid grid-cols-2 gap-4 pb-4">
              <p className="text-sm text-gray-600">Payment method</p>

              <ConfirmationPaymentMethodSection paymentMethod={paymentMethod} />
              <ConfirmationPaymentFrequencySection
                mode={mode}
                instalmentPlan={instalmentPlan}
              />
              <ConfirmationPaymentAmountSection
                mode={mode}
                amountInCents={payment?.amount}
                instalmentPlan={instalmentPlan}
              />
              <ConfirmationPaymentDateSection
                payment={payment}
                instalmentPlan={instalmentPlan}
              />
            </div>
          </div>
          {planRequest && (
            <div>
              <h4 className="mt-4 mb-2 font-medium text-gray-900">
                Request Details
              </h4>
              <div className="grid grid-cols-2 gap-4 pb-4">
                <ConfirmationPlanRequestSection planRequest={planRequest} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center flex-1">
        <button
          onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to home
        </button>
      </div>
    </div>
  );
};
