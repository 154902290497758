import {Debbie} from 'components/organisms/Debbie';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {goBack} from 'lib/navigation/routes';
import React, {useState} from 'react';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {
  getPaymentFrequencyOptions,
  PaymentFrequency,
} from '../shared/PaymentFrequencyOptions';

export const FlexiblePayXEveryZ = () => {
  const frequencyOptions = getPaymentFrequencyOptions();
  const [mode, setMode] = useState(frequencyOptions[0].value);
  const navigate = useSetupNavigate();

  return (
    <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie title="How often would you like to make payments?" />
        <div className="flex flex-col items-center justify-center flex-1 h-full">
          <StyledRadioGroup
            value={mode}
            options={frequencyOptions}
            onChange={e => setMode(e as PaymentFrequency)}
          />
          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => {
              navigate('/biller/:slug/setup/flexible/amount', {
                instalmentFrequency: mode,
              });
            }}
          >
            Next
          </button>
          <button
            onClick={goBack}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
