import React, {useEffect} from 'react';
import {RouteName} from 'lib/navigation/routes';
import {Route, RouteProps, Routes, useLocation} from 'react-router-dom';
import {LookupAccount} from './pages/LookupAccount';
import {ConfirmAccountPayment} from './pages/ConfirmAccountPayment';
import {ApolloProvider} from '@apollo/client';
import {publicClient} from 'lib/graphql/client';
import {PayNow} from './pages/PayNow';
import {PayNowFeatures} from './pages/PayNowFeatures';
import {FlexibleFeatures} from './pages/FlexibleFeatures';

const BASE_ROUTE: RouteName = '/biller/:slug/infringements';

export const ROUTES: RouteProps[] = [
  {
    path: `${BASE_ROUTE}/:type`,
    element: <LookupAccount />,
  },
  {
    path: `${BASE_ROUTE}/:type/confirm-payment`,
    element: <ConfirmAccountPayment />,
  },
  {
    path: `${BASE_ROUTE}/:type/pay-now`,
    element: <PayNow />,
  },
  {
    path: `${BASE_ROUTE}/:type/pay-now-features`,
    element: <PayNowFeatures />,
  },
  {
    path: `${BASE_ROUTE}/:type/flexible-features`,
    element: <FlexibleFeatures />,
  },
];

export const Infringement = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    document.title = 'Payble - Infringement';
  }, [pathname]);

  return (
    <div className="flex flex-col justify-start flex-1 w-full h-full overflow-hidden lg:py-8">
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <ApolloProvider client={publicClient}>
          <Routes>
            {ROUTES.map(({path, element}) => {
              const relativePath = path?.slice(BASE_ROUTE.length + 1);
              return (
                <Route
                  key={relativePath}
                  element={element}
                  path={relativePath}
                />
              );
            })}
          </Routes>
        </ApolloProvider>
      </div>
      {/* SVG Dot Pattern */}
      <div className="absolute inset-0 overflow-hidden">
        <svg
          className="absolute inset-0 top-0 overflow-hidden rounded-full left-1/2"
          width={404}
          height={600}
          fill="none"
          viewBox="0 0 404 300"
        >
          <defs>
            <pattern
              id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={600}
            fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
          />
        </svg>
      </div>
    </div>
  );
};
