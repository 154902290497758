import React, {useContext, useEffect} from 'react';
import {useActor} from '@xstate/react';
import {useParams} from 'react-router';

import * as Auth from 'lib/auth/Auth';
import {getBillerSlugFromUrl} from 'lib/url';
import {
  PaymentMethod,
  PaymentMethodStatus,
  useGetInstalmentPlanQuery,
  usePayInstalmentMutation,
} from 'lib/graphql/API';

import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import NumberFormat from 'react-number-format';
import {ChevronRightIcon, HomeIcon, PlusIcon} from '@heroicons/react/20/solid';
import {navigate} from 'lib/navigation/routes';
import {Debbie} from 'components/organisms/Debbie';
import {PaymentMethodRow} from '../components/PaymentMethodRow';
import {buttonClasses, classNames} from 'lib/styles';
import {ChoosePaymentMethod} from 'components/organisms/ChoosePaymentMethod';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {formatToDollars} from 'payble-shared';

export const PayNextInstalment: React.FC = () => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    document.title = 'Payble - Make Next Payment';
  }, []);

  // State for this control
  const [paymentMethodId, setPaymentMethodId] = React.useState('');
  const [showPaymentMethodChooser, setShowPaymentMethodChooser] =
    React.useState(false);

  // Get current payment method so we can show it as selected
  if (billerSlug === '') return <>Cannot load without a biller slug</>;
  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;
  const contact = authState.context.contact;
  if (!contact) return <>Cannot load without in context contact</>;

  const {
    loading: instalmentPlanLoading,
    error: instalmentPlanError,
    data: instalmentPlanData,
  } = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (instalmentPlanData) {
      setPaymentMethodId(
        instalmentPlanData.instalmentPlan?.paymentMethodId as string
      );
    }
  }, [instalmentPlanData]);

  const [
    payInstalment,
    {loading: payInstalmentLoading, error: payInstalmentError},
  ] = usePayInstalmentMutation();

  if (
    instalmentPlanLoading ||
    authState.matches({authenticated: 'refreshing'})
  ) {
    return <Loading />;
  }
  if (instalmentPlanError)
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );

  const pendingScheduledInstalment =
    instalmentPlanData?.instalmentPlan?.instalments.find(
      i =>
        i.type === 'setup' &&
        (i.status === 'scheduled' || i.status === 'overdue')
    );

  // Get the next instalment
  const nextInstalment =
    pendingScheduledInstalment ??
    instalmentPlanData?.instalmentPlan?.instalments.find(
      x => x.status === 'overdue' || x.status === 'scheduled'
    );

  if (!nextInstalment) return <>No payment to make</>;

  const onPayNextInstalment = async () => {
    const {errors} = await payInstalment({
      variables: {
        input: {
          instalmentPlanId,
          instalmentId: nextInstalment.instalmentId,
          paymentMethodId,
        },
      },
    });

    if (!errors) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
        slug: billerSlug,
        instalmentPlanId,
      });
    }
  };

  const contactPaymentMethod = contact.paymentMethods?.find(
    x => x.id === paymentMethodId
  );

  if (!contactPaymentMethod) {
    return <ErrorMessage message="Payment method not found." />;
  }

  const isPaymentMethodActive =
    contactPaymentMethod.status === PaymentMethodStatus.Active;

  return (
    <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie
          title="You can pay your next payment here"
          message="You can make your next payment below. If you need to, you can switch between payment methods for just this payment."
        />

        <div className="flex mb-2 mt-5">
          <div className="flex-1">
            <nav className="flex" aria-label="Breadcrumb">
              <ol
                role="list"
                className="bg-white rounded-md shadow px-6 flex space-x-4"
              >
                <li className="flex">
                  <div className="flex items-center">
                    <button
                      onClick={() =>
                        navigate('/biller/:slug', {slug: billerSlug})
                      }
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </button>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-6 h-full text-gray-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <button
                      onClick={() =>
                        navigate(
                          '/biller/:slug/instalment-plan/:instalmentPlanId',
                          {slug: billerSlug, instalmentPlanId}
                        )
                      }
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      Payment Plan
                    </button>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="flex pl-4">
            <button
              type="button"
              onClick={() =>
                navigate('/biller/:slug/profile/add-payment-method', {
                  slug: billerSlug,
                })
              }
              className={classNames(
                'transition inline-flex items-center px-4 md:py-[0.6rem] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                showPaymentMethodChooser ? '' : 'opacity-0'
              )}
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Add Payment Method
            </button>
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md mt-5">
          <div className="px-4 py-5 bg-white sm:p-6">
            {showPaymentMethodChooser ? (
              <ChoosePaymentMethod
                paymentMethods={contact.paymentMethods as PaymentMethod[]}
                selectedPaymentMethodId={paymentMethodId}
                onChange={e => {
                  setPaymentMethodId(e);
                  setShowPaymentMethodChooser(false);
                }}
              />
            ) : (
              <div className="relative flex items-start py-4">
                <PaymentMethodRow
                  paymentMethod={contactPaymentMethod as PaymentMethod}
                />
                <button
                  onClick={() => {
                    if (!payInstalmentLoading) {
                      setShowPaymentMethodChooser(true);
                    }
                  }}
                  className="ml-3 flex items-center place-self-center"
                >
                  <ChevronRightIcon className="h-10 w-10 text-gray-400 hover:text-gray-800" />
                </button>
              </div>
            )}

            {payInstalmentError && (
              <div>
                <ErrorMessage message={payInstalmentError.message} />
              </div>
            )}
            <button
              className={buttonClasses}
              onClick={onPayNextInstalment}
              disabled={
                showPaymentMethodChooser ||
                payInstalmentLoading ||
                !isPaymentMethodActive
              }
            >
              {payInstalmentLoading && <ButtonSpinner />}
              <span className="mr-1">Pay</span>
              <NumberFormat
                value={formatToDollars(nextInstalment.amount as number)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
