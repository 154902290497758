import {Debbie} from 'components/organisms/Debbie';
import {StyledRadioGroup} from 'features/setup/components/StyledRadioGroup';
import {goBack} from 'lib/navigation/routes';
import {useEffect, useMemo, useState} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {InstalmentPlanMode} from 'lib/graphql/API';
import {DatePicker} from 'features/setup/shared/DatePicker';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupRoute} from '../components/SetupRoute';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {AbsoluteDate, getBillerConfig} from 'payble-shared';
import {
  getPaymentFrequencyOptions,
  PaymentFrequency,
} from '../shared/PaymentFrequencyOptions';

export const BalancedPayFrequency = () => {
  const frequencyOptions = getPaymentFrequencyOptions();
  const [mode, setMode] = useState(frequencyOptions[0].value);
  const {account} = useSetupRoute();
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const {instalmentStartAt} = useSetupSearchParams();
  const {patch} = usePatchSearchParams();
  const navigate = useSetupNavigate();
  const [maxDate, setMaxDate] = useState(() =>
    AbsoluteDate.today({billerConfig}).plus({days: 30})
  );
  const minDate = useMemo(() => AbsoluteDate.today({billerConfig}), []);
  const [date, setDate] = useState(instalmentStartAt);

  const [dateError, setDateError] = useState<Error>();
  useEffect(() => {
    if (account.targetInstalments) {
      const {
        targetInstalments: [first],
      } = account;
      if (!first) {
        return;
      }
      setMaxDate(first.dueAt);
    }
  }, [account.targetInstalments]);

  return (
    <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full">
        <Debbie title="How often would you like to make payments?" />

        <div className="max-w-sm px-4 mx-auto mt-4 space-y-2 sm:px-6">
          <h4 className="font-semibold text-center">
            Choose when payments will start
          </h4>

          <DatePicker
            error={dateError}
            setError={setDateError}
            minDate={minDate}
            maxDate={maxDate}
            onChange={date => {
              patch({instalmentStartAt: date.toISO()}, {replace: true});
              setDate(date);
            }}
            value={date ?? undefined}
          />
        </div>

        {date && (
          <div className="flex flex-col items-center justify-center flex-1 h-full">
            <StyledRadioGroup
              value={mode}
              options={frequencyOptions}
              onChange={e => {
                setMode(e as PaymentFrequency);
                patch({instalmentStartAt: date.toISO()}, {replace: true});
              }}
            />
            <button
              type="button"
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={!!dateError}
              onClick={() => {
                navigate('/biller/:slug/setup/plan/preview', {
                  instalmentMode: InstalmentPlanMode.BalancedPayEveryX,
                  instalmentFrequency: mode,
                });
              }}
            >
              Next
            </button>
            <button
              onClick={goBack}
              className="mt-6 text-blue-600 transition hover:text-blue-700"
            >
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
