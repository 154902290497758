import {navigate as navigateTo} from 'lib/navigation/routes';
import {goBack} from 'lib/navigation/routes';
import React, {useEffect} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';

import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {markDownToHtml} from 'lib/markDownToHtml';

export const CatchupFeatures = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const navigate = useSetupNavigate();

  useEffect(() => {
    if (!billerConfig.hasCatchUpPlans) {
      navigateTo('/biller/:slug/setup', {slug: billerSlug});
    }
  }, [billerConfig]);

  const terms = billerConfig.catchUpPlanTerms;

  if (!terms) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong"
        debbieMessage=""
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="relative flex flex-col h-full items-center">
        <Debbie title="How does this look to you?" />
        <div className="max-w-sm flex flex-col items-center">
          <div className="shadow overflow-hidden rounded-md mt-4 ">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="flex-1 flex flex-col h-full justify-center">
                {terms.map((term, index) => (
                  <p
                    className="mt-2 mb-2 [&>a]:underline [&>ul]:list-disc [&>ul]:ml-4 [&>em]:italic [&>b]:font-bold [&>strong]:font-bold"
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: markDownToHtml(term, [
                        'a',
                        'b',
                        'strong',
                        'ul',
                        'li',
                        'em',
                      ]),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          <button
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed transition mt-4 w-full text-center justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => navigate('/biller/:slug/setup/catch-up/schedule')}
          >
            Accept and Continue
          </button>
          <button
            className="mt-6 text-blue-600 hover:text-blue-700 transition"
            onClick={() => goBack()}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
