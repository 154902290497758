import React, {useContext, useEffect, useState} from 'react';
import {useActor} from '@xstate/react';

import * as Auth from 'lib/auth/Auth';
import {getBillerSlugFromUrl} from 'lib/url';
import {navigate} from 'lib/navigation/routes';
import {
  AddPaymentMethodMutation,
  InstalmentPlan,
  PaymentMethodType,
  useAddPaymentMethodMutation,
  useGetInstalmentPlansQuery,
} from 'lib/graphql/API';

import {Debbie} from 'components/organisms/Debbie';
import {RadioGroup} from '@headlessui/react';
import {ProfileMenu} from '../components/ProfileMenu';

import {HomeIcon} from '@heroicons/react/20/solid';

import {useLocation} from 'react-router';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {DirectDebit} from 'components/organisms/DirectDebit';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {
  PAYMENT_METHODS,
  PaymentMethodPicker,
  PaymentType,
} from 'components/organisms/PaymentMethodPicker';
import {DirectDebitNZ} from '../../../components/organisms/DirectDebitNZ';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {toPaymentMethodType} from '../../../lib/types/DirectDebit';

type RedirectAfterAddPaymentMethod = {
  data: AddPaymentMethodMutation;
  instalmentPlanId: string | null;
  billerSlug: string;
  navigate: typeof navigate;
  instalmentPlans: InstalmentPlan[];
};

function redirectAfterAddPaymentMethod({
  data,
  instalmentPlanId,
  billerSlug,
  navigate,
  instalmentPlans,
}: RedirectAfterAddPaymentMethod) {
  const {paymentMethodId, updatedPlans} = data.addPaymentMethod;

  if (instalmentPlanId && !updatedPlans.length) {
    const queryStringParams = new URLSearchParams('');
    queryStringParams.append('paymentMethodId', paymentMethodId);
    return navigate(
      '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
      {
        instalmentPlanId: instalmentPlanId,
        slug: billerSlug,
      },
      false,
      queryStringParams
    );
  }

  if (updatedPlans.length === 1 && updatedPlans[0]) {
    return navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
      instalmentPlanId: updatedPlans[0].id,
      slug: billerSlug,
    });
  }

  return navigate(
    instalmentPlans.length > 0 ? '/biller/:slug' : '/biller/:slug/profile',
    {slug: billerSlug}
  );
}

export const AddPaymentMethod: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const queryParams = new URLSearchParams(useLocation().search);
  const instalmentPlanId = queryParams.get('instalmentPlanId');
  const billerConfig = useBillerConfig();

  const {data} = useGetInstalmentPlansQuery();

  const [paymentType, setPaymentType] = useState<PaymentType | undefined>(
    PAYMENT_METHODS.filter(({type}) =>
      billerConfig.paymentMethods.includes(toPaymentMethodType(type))
    )[0]
  );

  useEffect(() => {
    document.title = 'Payble - Add Payment Method';
  }, []);

  const [
    addPaymentMethod,
    {loading: addPaymentMethodLoading, error: addPaymentMethodError},
  ] = useAddPaymentMethodMutation();

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, usePaymentMethodForPlans}) => {
    const {errors, data} = await addPaymentMethod({
      variables: {
        input: {
          paymentMethodType: PaymentMethodType.Card,
          card,
          usePaymentMethodForPlans,
        },
      },
    });

    if (errors) {
      console.error(errors);
      return;
    }

    if (data && data.addPaymentMethod) {
      return redirectAfterAddPaymentMethod({
        data,
        instalmentPlanId,
        billerSlug,
        navigate,
        instalmentPlans: authState.context.instalmentPlans,
      });
    }
  };

  const onBankComplete: React.ComponentProps<
    typeof DirectDebit
  >['onComplete'] = async ({bank, usePaymentMethodForPlans}) => {
    const {errors, data} = await addPaymentMethod({
      variables: {
        input: {
          paymentMethodType: PaymentMethodType.DirectDebit,
          bank,
          usePaymentMethodForPlans,
        },
      },
    });

    if (errors) {
      console.error(errors);
      return;
    }

    if (data && data.addPaymentMethod) {
      return redirectAfterAddPaymentMethod({
        data,
        instalmentPlanId,
        billerSlug,
        navigate,
        instalmentPlans: authState.context.instalmentPlans,
      });
    }
  };

  const onNZBankComplete: React.ComponentProps<
    typeof DirectDebitNZ
  >['onComplete'] = async ({nzBank, usePaymentMethodForPlans}) => {
    const {errors, data} = await addPaymentMethod({
      variables: {
        input: {
          paymentMethodType: PaymentMethodType.NzDirectDebit,
          nzBank: {
            accountName: nzBank.accountName,
            accountNumber: nzBank.accountNumber.toJSON(),
          },
          usePaymentMethodForPlans,
        },
      },
    });

    if (errors) {
      console.error(errors);
      return;
    }

    if (data && data.addPaymentMethod) {
      return redirectAfterAddPaymentMethod({
        data,
        instalmentPlanId,
        billerSlug,
        navigate,
        instalmentPlans: authState.context.instalmentPlans,
      });
    }
  };

  let profileName = 'Your';
  if (authState.context.contact?.givenName) {
    profileName = authState.context.contact.givenName;
    profileName = profileName.endsWith('s')
      ? `${profileName}'`
      : `${profileName}'s`;
  }

  const account = data?.instalmentPlans?.find(
    plan => plan.id === instalmentPlanId
  )?.account;

  if (addPaymentMethodError) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage={`${addPaymentMethodError.message}. Please try again by clicking below`}
        onClick={() => {
          navigate('/biller/:slug/profile', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <div className="flex flex-col flex-1 w-full h-full overflow-hidden">
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <div className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="relative flex flex-col h-full">
            <Debbie
              title={'Add a payment method'}
              message="You can add payment methods for ongoing or once off payments."
            />

            <div className="flex mt-5 mb-2">
              <div className="flex-1">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol
                    role="list"
                    className="flex px-6 space-x-4 bg-white rounded-md shadow"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            navigate('/biller/:slug', {slug: billerSlug})
                          }
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <HomeIcon
                            className="flex-shrink-0 w-5 h-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Home</span>
                        </button>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 w-6 h-full text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <button
                          onClick={() =>
                            navigate('/biller/:slug/profile', {
                              slug: billerSlug,
                            })
                          }
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        >
                          {profileName} profile
                        </button>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="flex pl-4">
                <ProfileMenu slug={billerSlug} />
              </div>
            </div>

            <div className="" id="payment-section">
              <RadioGroup value={paymentType} onChange={setPaymentType}>
                <PaymentMethodPicker
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  billerSlug={billerSlug}
                  accountType={account?.type}
                />
              </RadioGroup>

              <div
                className={
                  paymentType?.type === PaymentMethodType.NzDirectDebit
                    ? 'min-w-full'
                    : 'hidden'
                }
              >
                <DirectDebitNZ
                  onComplete={onNZBankComplete}
                  contact={authState.context.contact}
                  disabled={addPaymentMethodLoading}
                  mode={'add'}
                />
              </div>

              <div
                className={
                  paymentType?.type === PaymentMethodType.DirectDebit
                    ? 'min-w-full'
                    : 'hidden'
                }
              >
                <DirectDebit
                  onComplete={onBankComplete}
                  contact={authState.context.contact}
                  disabled={addPaymentMethodLoading}
                  mode={'add'}
                />
              </div>
              <div
                className={
                  paymentType?.type === PaymentMethodType.Card
                    ? 'min-w-full'
                    : 'hidden'
                }
              >
                <TokenExCreditCardForm
                  onCardComplete={onCardComplete}
                  contact={authState.context.contact}
                  disabled={addPaymentMethodLoading}
                  mode={'add'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
