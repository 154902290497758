import {Loading} from 'components/atoms/Loading';
import {Debbie} from 'components/organisms/Debbie';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {usePayAccountAnonymouslyMutation} from 'lib/graphql/publicAPI';
import {getBillerSlugFromUrl} from 'lib/url';
import React from 'react';
import {useInfringementParams} from '../hooks/useInfringementParams';
import {PaymentStatus} from '../components/PaymentStatus';
import {useBrowserBack} from 'lib/navigation/useBrowserBack';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {useGetAuthKey} from 'lib/auth/useGetAuthKey';

export const PayNow: React.FC = () => {
  const {data: account, loading: loadingAccount} =
    useGetAccountFromSearchParams({anonymous: true});
  const billerSlug = getBillerSlugFromUrl();
  const {verificationCode} = useInfringementParams();
  const [pay, {data: payResult, loading: paying, error: errorPaying}] =
    usePayAccountAnonymouslyMutation();
  const navigate = useInfringementNavigate();

  useBrowserBack(() => {
    navigate('/');
  });

  const {loading: loadingTokenEx} = useGetAuthKey({
    anonymous: true,
  });

  const loading = loadingAccount || paying || loadingTokenEx;

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, contact, sendReceipts}) => {
    await pay({
      variables: {
        input: {
          accountId: account.id,
          amountInCents: account.amountOwing,
          card,
          billerSlug,
          verificationCode,
          sendReceipt: sendReceipts ?? false,
          email: contact.email,
        },
      },
    });
  };

  return (
    <div
      className="relative w-full h-full px-4 py-6 mx-auto sm:px-6 lg:py-16 lg:px-8"
      data-testid="paymentMethod"
    >
      <div className="relative flex flex-col h-full">
        {payResult ? (
          <>
            <Debbie title="Your payment has been processed!" />
            <PaymentStatus
              account={account}
              payment={payResult.payAccountAnonymously}
            />
            <button
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                navigate('/');
              }}
            >
              Back to home
            </button>
          </>
        ) : (
          <>
            <Debbie
              title="You’re almost there!"
              message="Choose your payment method"
            />

            {loading ? (
              <Loading />
            ) : (
              <div
                className="relative max-w-2xl px-4 mx-auto mt-4 sm:px-6 lg:max-w-7xl lg:px-8"
                id="payment-section"
              >
                <div className="min-w-full">
                  <TokenExCreditCardForm
                    anonymous
                    showSendReceipts
                    proceedButtonText="Acknowledge and pay"
                    onCardComplete={onCardComplete}
                    onCompleteError={errorPaying?.message}
                    disabled={loading}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
